

    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";
    import { LookupItem } from '@/model/LookupItem';

    @Component({})
    export default class MultiSelectList extends Vue {

        @Prop({ required: true })
        private idList!: Array<number>;

        @Prop({ required: true })
        private lookupList!: Array<LookupItem>;

        selectedIdToAdd: number = 0;

        get selectedList(): Array<LookupItem> {
            return this.lookupList.filter(l => this.idList.indexOf(l.id) !== -1);
        }

        get availableList(): Array<LookupItem> {
            const arr = this.lookupList.filter(l => this.idList.indexOf(l.id) === -1 && l.id !== 0);
            return [new LookupItem({ id: 0, description: "Choose item to add...", isArchived: false }), ...arr];
        }

        addItem() {
            const items = this.lookupList.filter(l => l.id === this.selectedIdToAdd);
            // if(!items) {
            //     console.log("---- items null");
            //     return;
            // }
            // if(!items[0]) {
            //     console.log("---- items[0] null ... item count = " + items.length + " selected ID = " + this.selectedIdToAdd);
            //     return;
            // }
            if(!items[0].id) {
                console.log("---- items[0] has empty ID");
                return;
            }
            if(!items[0]) return;
            // console.log("---- adding ID", items[0].id);           
            this.idList.push(items[0].id);
            this.selectedIdToAdd = 0;            
        }

        removeItem(lookupItem: LookupItem) {
            if(!LookupItem) return;
            const index = this.idList.indexOf(lookupItem.id);
            if (index === -1) return;
            this.idList.splice(index, 1);
        }
    }


import { TriState } from "./Enums";

export class BuyerSupplierSearchParameters {
    pageNumber: number = 0;
    searchText: string = "";
    id: number = 0;
    buyerID: number = 0;
    supplierID: number = 0;
    isDeleted: TriState = TriState.UseDefault;
    isSupplierZone: boolean = false;
}
import { mapData } from "@/stuff/DataMapping";

export interface IBuyerSupplierModule {
    moduleID: number;
    description: string;
    isMandatory: boolean;
    isSelected: boolean;
}

export class BuyerSupplierModule implements IBuyerSupplierModule {

    constructor(data?: IBuyerSupplierModule) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IBuyerSupplierModule) {
        mapData<BuyerSupplierModule>(data, { root: () => this });
    }

    moduleID: number = 0;
    description: string = "";
    isMandatory: boolean = false;
    isSelected: boolean = false;
}
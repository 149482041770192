
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import MultiDocument from "@/components/MultiDocument.vue";
    import DatePicker from "@/components/DatePicker.vue";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import { Prop } from "vue-property-decorator";
    import { StateChanger } from "vue-infinite-loading";
    import * as toastr from "toastr";
    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { LookupItem, ILookupItem } from "@/model/LookupItem";
    import { ICommunication, Communication } from "@/model/Communication";
    import { CommunicationSearchParameters } from "@/model/CommunicationSearchParameters";
    import { TriState, UserRole } from "@/model/Enums";
    import { Document as ModelDocument } from "@/model/Document";
    import { RegionSearchParameters } from "@/model/RegionSearchParameters";
    import { UserSearchParameters } from "@/model/UserSearchParameters";
    import { IContact } from "@/model/Contact";
    import { ContactSearchParameters } from "@/model/ContactSearchParameters";
    import { FollowUp } from "@/model/FollowUp";

    interface IBuyerWithUsers extends ILookupItem {
        users: Array<ILookupItem>;
    }

    @Component({
        components: { ApiButton, MultiDocument, DatePicker }
    })
    export default class Communications extends Vue {

        constructor() {
            super();
            // setting parameters here to avoid tripping watchers...
            this.searchParameters.isClosed = TriState.False;
            this.searchParameters.isBuyer = TriState.True;
            this.searchParameters.supplierID = this.supplierID;
        }

        async mounted() {

            await Promise.all([
                store.dispatch("loadCommunicationTypeList"),
                store.dispatch("loadUserList"),
                this.loadCqmsUsers(),
                this.loadBuyers(),
                this.loadBuyersRegionOptions(),
                this.loadNotifyUserList(),
                this.loadNotifyContactList(),
                this.getAssignmentOptions()
            ]);
            // No need to load communications - the infinite loading component will run the search...
        }

        //
        // -- properties
        //

        @Prop({ default: 0 })
        private supplierID!: number;

        private readonly searchParameters = new CommunicationSearchParameters();
        private totalCount = -1;
        private readonly communication = new Communication();
        private communicationList: Array<ICommunication> = [];
        private notifyUserBuyerIdFilter: number = 0;
        private notifyUserRegionIdFilter: number = 0;
        private notifyUserIdToAdd: number = 0;
 
        private get userList(): Array<ILookupItem> { return this.$store.state.userList; }
        private cqmsUserOptions: Array<ILookupItem> = [];
        private buyerList: Array<IBuyerWithUsers> = [];
        private assignToOptions: Array<ILookupItem> = []

        private get notifyUserBuyerOptions(): Array<ILookupItem> { 
            const filtered = this.buyerList.filter((lu: ILookupItem) => this.communication.buyerIDs.indexOf(+lu.id) >= 0);
            return filtered.length === 0
                ? [new LookupItem({ id: 0, description: "No buyers ticked", isArchived: false } as ILookupItem)]
                : [new LookupItem({ id: 0, description: "Any buyer...", isArchived: false } as ILookupItem), ...filtered];
        }

        //private notifyUserBuyerOptions: Array<ILookupItem> = [];
        private notifyUserRegionOptions: Array<ILookupItem> = [];
        private notifyUserList: Array<ILookupItem> = [];
        private notifyUserOptions: Array<ILookupItem> = [];
        private notifyContactList: Array<IContact> = [];

        // private get notifyUserOptions(): Array<ILookupItem> { 
        //     if(this.communication.buyerIDs.length === 0) {
        //         [new LookupItem({ id: 0, description: "No buyers ticked", isArchived: false } as ILookupItem)];
        //     }
        //     const choose = new LookupItem({ id: 0, description: "Choose user...", isArchived: false } as ILookupItem);
        //     return [choose, ...this.notifyUserList.filter((userLookup: ILookupItem) => {
        //         if(userLookup.isArchived) return false;
        //         if(!userLookup.id) return false;
        //         if(this.communication.notifyUserIDs.includes(+userLookup.id)) return false;
        //         return true;
        //     })];
        // }

        private get communicationTypeList(): Array<ILookupItem> { return this.$store.state.communicationTypeList; }
        private get communicationTypeOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.communicationTypeList); }
        private get communicationTypeSearchOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.communicationTypeList, "Any type..."); }

        private get isClosedSearchOptions(): Array<LookupItem> { return [
            new LookupItem({ id: TriState.UseDefault, description: "Any", isArchived: false }),
            new LookupItem({ id: TriState.True, description: "Closed", isArchived: false }),
            new LookupItem({ id: TriState.False, description: "Not Closed", isArchived: false })
        ];}

        private get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        // can't easily access enum in template? so...
        private get isSearchBuyers(): boolean {
            return this.searchParameters.isBuyer === TriState.True;
        }

        private get communicationDialogueTitle(): string {
            if (!this.communication) return "- - -";
            if (this.communication.isNew) return "New Communication";
            return "Edit " + this.communication.description;
        }

        private typeDescription(communication: Communication): string {
            return utils.lookupDescription(communication.typeID, this.communicationTypeList);
        }

        private get canCloseCommunication() {
            return !this.communication.isNew && !this.communication.isClosed;
        }

        private get canSaveAndNotify() {
            return !this.communication.isClosed && 
                    (
                        (this.communication.isBuyer && this.communication.notifyUserIDs.length > 0) ||
                        (!this.communication.isBuyer && this.notifyContactList.length > 0)
                    );
        }

        private get saveCommunicationButtonText() {
            return utils.hasDateValue(this.communication.closed) ? "Save and Re-open" : "Save";
        }

        private get buyerIDsText() {
            return this.getBuyersHtml(this.communication);
        }

        private getBuyersHtml(communication: Communication): string {
            let text = "";
            let delimiter = "";
            for(const id of communication.buyerIDs) {
                text += delimiter + utils.lookupDescription(id, this.buyerList);
                delimiter = "<br>";
            }
            return text || "(none)";
        }

        private getAttachmentsHtml(communication: Communication): string {
            if(!communication || !communication.documents) return "(null)";
            let text = "";
            let delimiter = "";
            for(const document of communication.documents) {
                text += delimiter + "<img height='20' src='" + utils.iconUrl(document) + "' /> <span class='fileName'>" + document.originalFilename  + "</span>";
                delimiter = "<br>";
            }
            return text || "- - -";
        }

        private getCreatedHtml(communication: Communication): string {
            return `${utils.dateText(communication.created)} <span class='lightText'>by</span> ${utils.lookupDescription(communication.createdByUserID, this.userList)}`;
        }

        private contactListHtml(communication: Communication): string {
            if(this.communication.isBuyer) return "- - -";
            if(this.notifyContactList.length === 0) return "No contacts!"
            let html = "<ul>";
            for(const contact of this.notifyContactList) {
                const name = utils.escapeForHtml(contact.forename + " " + contact.surname);
                if(contact.doNotChase) {
                    html += `<li><span style="text-decoration:line-through">${name}</span> (do not chase)</li>`;
                }
                else if(utils.isEmptyOrWhitespace(contact.email)) {
                    html += `<li><span style="text-decoration:line-through">${name}</span> (missing email)</li>`;
                }
                else {
                    html += `<li>${name} (${utils.escapeForHtml(contact.email)})</li>`;
                }
            }
            html += "</ul>"
            return html;
        }

        private showFollowUpIcon(communication: Communication): boolean {
            return communication.followUp != null;
        }
        private followUpIcon(communication: Communication): string {
            if (this.showFollowUpIcon(communication) && communication.followUp!.followUpDate < new Date()) return "fas fa-alarm-exclamation";
            else return "fas fa-alarm-clock";
        }
        private followUpColour(communication: Communication): string {
            if (this.showFollowUpIcon(communication) && communication.followUp!.followUpDate < new Date()) return "red";
            else return "black";
        }
        private followUpTitle(communication: Communication): string {
            if (!this.showFollowUpIcon(communication)) return "";
            else if (utils.isEmptyId(communication.followUp!.assignedToUserID)) return `Follow up by ${utils.dateText(communication.followUp!.followUpDate)}`;
            else return `Follow up by ${utils.dateText(communication.followUp!.followUpDate)}, assigned to ${utils.lookupDescription(communication.followUp!.assignedToUserID, this.userList)}`;
        }

        private get supplierCommsButtonVariant(): string {
            return this.searchParameters.isBuyer  === TriState.True ? "outline-primary" : "primary";
        }

        private get buyerCommsButtonVariant(): string {
            return this.searchParameters.isBuyer === TriState.True ? "primary" : "outline-primary";
        }

        private get hasFollowUp(): boolean {
            return this.communication.followUp != null;
        }
        private set hasFollowUp(value: boolean) {
            if (value) this.communication.followUp = new FollowUp();
            else this.communication.followUp = null;
        }

        //
        // -- watchers
        //

        @Watch("supplierID")
        onSupplierIdChanged() {
            this.searchParameters.supplierID = this.supplierID;
            this.refreshSearch();
        }

        @Watch("searchParameters.typeID")
        onTypeIdChanged() {
            this.refreshSearch();
        }

        @Watch("searchParameters.isClosed")
        onIsClosedChanged() {
            this.refreshSearch();
        }

        @Watch("searchParameters.isBuyer")
        onIsBuyerChanged() {
            this.refreshSearch();
        }
        
        @Watch("communication.buyerIDs")
        async onBuyerIDsSelectionChanged() {
            await this.loadBuyersRegionOptions();
            await this.loadNotifyUserList();
        }

        @Watch("notifyUserBuyerIdFilter")
        async onNotifyUserBuyerIdFilterChanged() {
            await this.loadBuyersRegionOptions();
            await this.loadNotifyUserList();
        }

        @Watch("notifyUserRegionIdFilter")
        async onNotifyUserRegionIdFilterChanged() {
            await this.loadNotifyUserList();
        }

        //
        // -- methods
        //

        async getAssignmentOptions() {
            const options = [];
            const params = new UserSearchParameters();
            params.isDeleted = TriState.False;
            params.isDormant = TriState.False;

            params.role = UserRole.Admin;
            let response = await apiClient.post("/api/user/searchLookups", params)
            options.push(...response)

            params.role = UserRole.Cqms;
            response = await apiClient.post("/api/user/searchLookups", params)
            options.push(...response)

            options.sort((a,b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0));

            this.assignToOptions = utils.selectOptions(options, "Unassigned");
        }

        private selectSupplierComms() {
            this.searchParameters.isBuyer = TriState.False;
        }

        private selectBuyerComms() {
            this.searchParameters.isBuyer = TriState.True;
        }

        private async loadCqmsUsers() {
            const parameters = new UserSearchParameters();
            parameters.role = UserRole.Cqms;
            const cqmsUsers: Array<ILookupItem> = await apiClient.post("/api/user/searchLookups", parameters);
            const choose = new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem);
            this.cqmsUserOptions = [choose, ...cqmsUsers.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        private async loadBuyers() {
            this.buyerList = await apiClient.get(`/api/buyer/lookups?supplierID=${this.supplierID}&withusers=true`);
            this.checkSelections();
        }

        private async loadBuyersRegionOptions() {
            if(this.communication.buyerIDs.length === 0) {
                this.notifyUserRegionOptions = [new LookupItem({ id: 0, description: "No buyers ticked", isArchived: false } as ILookupItem)];
                this.checkSelections();
                return;
            }
            const parameters = new RegionSearchParameters();
            // filter by selected buyers for communication if no specific buyer filter applied
            parameters.buyerIDs = this.notifyUserBuyerIdFilter 
                ? [this.notifyUserBuyerIdFilter] 
                : this.communication.buyerIDs;
            const regions: Array<ILookupItem> = await apiClient.post("/api/region/searchLookups", parameters);
            const choose = new LookupItem({ id: 0, description: "Any Region...", isArchived: false } as ILookupItem);
            this.notifyUserRegionOptions = [choose, ...regions.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)];
        }

        private async loadNotifyUserList() {
            if(this.communication.buyerIDs.length === 0) {
                this.notifyUserList = [];
                this.notifyUserOptions = [new LookupItem({ id: 0, description: "No buyers ticked", isArchived: false } as ILookupItem)];
                this.checkSelections();
                return;
            }
            const parameters = new UserSearchParameters();
            // filter by selected buyers for communication if no specific buyer filter applied
            parameters.buyerIDs = this.notifyUserBuyerIdFilter 
                ? [this.notifyUserBuyerIdFilter] 
                : this.communication.buyerIDs;
            parameters.regionID = this.notifyUserRegionIdFilter;
            parameters.role = UserRole.Buyer;
            this.notifyUserList = await apiClient.post("/api/user/searchLookups", parameters);

            const choose = new LookupItem({ id: 0, description: "Choose user...", isArchived: false } as ILookupItem);
            this.notifyUserOptions = [choose, ...this.notifyUserList.filter((userLookup: ILookupItem) => {
                if(userLookup.isArchived) return false;
                if(!userLookup.id) return false;
                if(this.communication.notifyUserIDs.includes(+userLookup.id)) return false;
                return true;
            })]
            this.checkSelections();
        }

        private async loadNotifyContactList() {
            const parameters = new ContactSearchParameters();
            parameters.supplierID = this.supplierID;
            // the search is paged (currently) but doesn't need to be as contacts will be few
            const response = await apiClient.post("/api/contact/search", parameters);
            // TODO - warn if there more contacts than the page size?
            this.notifyContactList = response.list;
        }

        private buyerDescription(userID: number): string {
            for(const buyer of this.buyerList) {
                for(const user of buyer.users) {
                    if(user.id === userID) return buyer.description;
                }
            }
            return "(no buyer)";
        }

        // private isUserIdInCommnicationBuyers(userID: number): boolean {
        //     // there's probably a fancy way to do this with Array.flatten or Array.reduce or something but sod it.
        //     for(const buyer of this.buyerList) {
        //         for(const user of buyer.users) {
        //             if(user.id === userID) return true;
        //         }
        //     }
        //     return false;
        // }

        private checkSelections() {
            console.log("# checkSelections");

            const buyerSelectionExists = this.notifyUserBuyerOptions.some(lu => lu.id === this.notifyUserBuyerIdFilter);
            if(!buyerSelectionExists) this.notifyUserBuyerIdFilter = 0;
            
            const regionSelectionExists = this.notifyUserRegionOptions.some(lu => lu.id === this.notifyUserRegionIdFilter);
            if(!regionSelectionExists) this.notifyUserRegionIdFilter = 0;

            const userSelectionExists = this.notifyUserOptions.some(lu => lu.id === this.notifyUserIdToAdd);
            if(!userSelectionExists) this.notifyUserIdToAdd = 0;

            // make a copy of array
            const selecteduserIDs = this.communication.notifyUserIDs.slice();
            console.log("selected IDs before = ", selecteduserIDs);
            console.log("whole user list = ", selecteduserIDs);
            for(const userID of selecteduserIDs) {
                if(this.isUserIDInSelectedBuyers(userID)) {
                    console.log(`  - id = ${userID} is okay.`);
                    continue;
                }
                const index = this.communication.notifyUserIDs.indexOf(userID);
                console.log(`  - id = ${userID} needs to go. index = ${index}`);
                this.communication.notifyUserIDs.splice(index, 1);
                console.log("  - updated array:", this.communication.notifyUserIDs);
            //this.loadNotifyUserList();
            }
        }

        private isUserIDInSelectedBuyers(userID: number): boolean {
            const buyers = this.buyerList.filter(b => this.communication.buyerIDs.includes(+b.id));
            for(const buyer of buyers) {
                for(const user of buyer.users) {
                    if(user.id === userID) return true;
                }
            }
            return false;
        }

        private async refreshSearch(event?: Event) {
            this.communicationList = [];
            this.totalCount = -1;
            await this.search(event);
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/Communication/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
                this.$emit("communicationCountChanged", response.count); // update tab count badge in parent
            }
            // add new 'page' to existing list
            this.communicationList.push(...response.list.map((s: ICommunication) => new Communication(s)));
        }

        private async editCommunication(communicationData: ICommunication) {
            this.communication.followUp = null;
            this.communication.update(communicationData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("communicationDialogue");
            // refresh from server
            const serverCommunicationData = await apiClient.get(`api/communication/Load?id=${communicationData.id}`);
            this.communication.update(serverCommunicationData);
        }

        private editNewCommunication() {
            this.communication.followUp = null;
            utils.resetObject(this.communication);
            this.communication.documents = [];
            this.communication.documentIDs = [];
            this.communication.buyerIDs = [];
            this.communication.isBuyer = this.searchParameters.isBuyer === TriState.True;
            this.$v.$reset();
            this.communication.supplierID = this.supplierID;
            this.$bvModal.show("communicationDialogue");
        }

        private cancelCommunication() {
            this.$bvModal.hide("communicationDialogue");
            utils.resetObject(this.communication);
        }

        addNotifyUserId() {
            if(!this.notifyUserIdToAdd) return;
            this.communication.notifyUserIDs.push(this.notifyUserIdToAdd);
            this.notifyUserIdToAdd = 0;
            this.loadNotifyUserList();
        }

        removeNotifyUser(userID: number) {
            const index = this.communication.notifyUserIDs.indexOf(userID);
            if (index === -1) return;
            this.communication.notifyUserIDs.splice(index, 1);
            this.loadNotifyUserList();
        }

        private async saveCommunication(event: Event, shouldNotify: boolean) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.communication.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }

            // confirm message (but show only if notify)
            const title = this.communication.isNew 
                ? "New Communication" 
                : "Existing Communication";
            const message = this.communication.isBuyer 
                ? "Saving the new communication will send notifications out to the buyer users you selected."
                : "Saving the new communication will send emails out to all the contacts for the supplier."
            const okTitle = shouldNotify ? "Send Now" : "Save Changes";

            const userSaidYes = !shouldNotify || await this.$bvModal.msgBoxConfirm(message, {
                title: title,
                okVariant: "danger",
                okTitle: okTitle,
                cancelTitle: "Cancel",
                hideHeaderClose: true,
                centered: true
            });
            if(shouldNotify && !userSaidYes) return;
            this.sendSaveRequest(event, shouldNotify);
       }

        private async sendSaveRequest(event: Event, shouldNotify: boolean) {
            const response: { newID: number; sentCount: number } = await apiClient.post(`/api/communication/save?shouldNotify=${shouldNotify ? "true" : "false"}`, this.communication, event);
            if (this.communication.isNew) {
                this.communication.id = response.newID;
            }
            toastr.success(shouldNotify ? `Saved and sent ${response.sentCount} email${response.sentCount === 1 ? "" : "s"}` :  "Saved");
            this.$bvModal.hide("communicationDialogue");
            await this.refreshSearch();
        }

        private async closeCommunication(event: Event) {
            if (this.communication.isNew) return;
            const shouldClose: boolean = await this.$bvModal.msgBoxConfirm("Do you want to close '" + this.communication.description + "'?", {
                title: "Close Communication",
                okVariant: "warning",
                okTitle: "Yes, close!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldClose) return;
            this.communication.closed = new Date();
            await apiClient.post("/api/communication/close", this.communication, event);
            toastr.warning("Closed");
            this.$bvModal.hide("communicationDialogue");
            await this.refreshSearch();
        }

        private async deleteCommunication(event: Event) {
            if (this.communication.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.communication.description + "'?", {
                title: "Delete Communication",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/communication/delete", this.communication, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("communicationDialogue");
            await this.refreshSearch();
        }

        private onUploaded(documentToAdd: ModelDocument) {
            this.communication.documents.push(documentToAdd);
            // no point adding ID as it won't have one until it's saved
        }

        private onRemove(documentToRemove: ModelDocument) {
            this.communication.documents = this.communication.documents.filter(d => d.filename !== documentToRemove.filename);
            this.communication.documentIDs = this.communication.documentIDs.filter(id => id != documentToRemove.id);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.communicationList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.communicationList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        @Validations()
        validations() {
            const validations = {
                communication: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.communication.followUp = {} as any;
            validations.communication.followUp.followUpDate =  { isValid: (value: Date) => !this.hasFollowUp || utils.hasDateValue(value) };
            validations.communication.details = { required };
            validations.communication.typeID = { isNonZero: (value: number) => +value > 0 };
            validations.communication.buyerIDs = { atLeastOneIfBuyer: (value: Array<number>, comm: ICommunication) => !comm.isBuyer || value.length > 0 };
            return validations;
        }
    }

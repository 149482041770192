
    import Vue from "vue";
    import Component from "vue-class-component";
    import { Prop } from "vue-property-decorator";

    @Component({})
    export default class TickOrCross extends Vue {

        @Prop({ default: false })
        private isTicked!: boolean;

        get cssClasses(): string {
            return this.isTicked ? "fas fa-check-circle tickOrCrossTick" : "fas fa-times-circle tickOrCrossCross";
        }
    }


    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import { required, email } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import { Prop } from "vue-property-decorator";
    import * as toastr from "toastr";
    import apiClient from "@/stuff/ApiClient";
    import { IContact, Contact } from "@/model/Contact";
    import {ContactSearchParameters } from "@/model/ContactSearchParameters";
    import utils from "@/stuff/Utils";
    import { TriState } from "@/model/Enums";

    @Component({
        components: { ApiButton }
    })
    export default class Contacts extends Vue {

        mounted() {
            // console.log("@@@ Contacts mounted supplierID = ", this.supplierID);
            this.isSupplierZone = this.$router.currentRoute.path.toLowerCase().indexOf("supplierzone") > -1;
            this.load();
        }

        beforeDestroy() {
            console.log("💀💀💀 Contacts beforeDestroy");
        }
        //
        // -- properties
        //

        @Prop({ default: 0 })
        private regionID!: number;

        @Prop({ default: 0 })
        private supplierID!: number;

        private isSupplierZone: boolean = false;

        private isWaiting: boolean = true;

        readonly contact = new Contact();

        contactList: Array<IContact> = [];
        
        private get contactDialogueTitle(): string {
            if (!this.contact) return "- - -";
            if (utils.isEmptyId(this.contact.id)) return "New Contact";
            return "Edit " + this.contact.fullname;
        }

        get canDeleteContact() {
            return !this.contact.isNew && !utils.hasDateValue(this.contact.deleted);
        }

        private get saveContactButtonText() {
            return utils.hasDateValue(this.contact.deleted) ? "Save Contact and Un-archive" : "Save Contact";
        }

        //
        // -- watchers
        //

        @Watch("supplierID")
        onSupplierIdChanged() {
            this.load();
        }

        @Watch("regionID")
        onRegionIdChanged() {
            this.load();
        }

        //
        // -- methods
        //

        async load() {
            this.$v.$reset();
            utils.resetObject(this.contact);
            this.contactList = [];
            await this.refreshContacts();
        }

        async refreshContacts() {
            utils.resetObject(this.contact);
            const parameters = new ContactSearchParameters(); // TODO - make parameters a property?
            parameters.regionID = this.regionID;
            parameters.supplierID = this.supplierID;
            parameters.isDeleted = TriState.False;

            this.isWaiting = true;

            const responseData = await apiClient.post("api/contact/search", parameters);
            this.contactList = responseData.list.map((c: IContact) => new Contact(c));
            console.log("~~~~ New contact count = ", responseData.count);
            this.$emit("contactCountChanged", responseData.count); // update tab count badge in parent
            this.isWaiting = false;
        }

        async editContact(contactData: IContact) {
            this.contact.update(contactData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("contactDialogue");
            // refresh from server
            const serverContactData = await apiClient.get(`api/contact/Load?id=${contactData.id}`);
            this.contact.update(serverContactData);
        }

        editNewContact() {
            utils.resetObject(this.contact);
            this.$v.$reset();
            this.contact.regionID = this.regionID;
            this.contact.supplierID = this.supplierID;
            this.$bvModal.show("contactDialogue");
        }

        cancelContact() {
            this.$bvModal.hide("contactDialogue");
            utils.resetObject(this.contact);
        }

        async saveContact(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.contact.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/contact/save", this.contact, event);
            if (this.contact.isNew) {
                this.contact.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("contactDialogue");
            await this.refreshContacts();
        }

        async deleteContact(event: Event) {
            if (this.contact.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.contact.fullname + "'?", {
                title: "Delete Contact",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/contact/delete", this.contact, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("contactDialogue");
            await this.refreshContacts();
        }

        @Validations()
        validations() {
            const validations = {
                contact: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.contact.forename = { required };
            validations.contact.surname = { required };
            validations.contact.email = { email };
            return validations;
        }
    }

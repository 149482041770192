
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import DatePicker from "@/components/DatePicker.vue";
    import FileUpload from "@/components/FileUpload.vue";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import { Prop } from "vue-property-decorator";
    import * as toastr from "toastr";
    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { LookupGroup, TriState, UserRole } from "@/model/Enums";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { IHistory, History } from "@/model/History";
    import { HistorySearchParameters } from "@/model/HistorySearchParameters";
    import { FollowUp } from "@/model/FollowUp";
    import { UserSearchParameters } from "@/model/UserSearchParameters";
    import { Document as ModelDocument } from "@/model/Document";
    import fileDownload from "@/stuff/FileDownload";

    @Component({
        components: { ApiButton, DatePicker, FileUpload }
    })
    export default class Histories extends Vue {

        async mounted() {
            this.searchParameters.supplierID = this.supplierID;
            await Promise.all([
                store.dispatch("loadUserList"),
                this.loadHistoryTypes(),
                this.load(),
                this.getAssignmentOptions()
            ]);
        }

        //
        // -- properties
        //

        @Prop({ default: 0 })
        private supplierID!: number;

        private isWaiting: boolean = true;

        private readonly history = new History();
        private searchParameters = new HistorySearchParameters();
        private historyList: Array<IHistory> = [];

        private historyTypeList: Array<ILookupItem> = [];
        private historyTypeOptions: Array<ILookupItem> = [];
        private historyTypeSearchOptions: Array<ILookupItem> = [];


        // private get historyTypeList(): Array<ILookupItem> { return utils.enumToLookups(HistoryType); }
        // private get historyTypeOptions(): Array<ILookupItem> { return utils.selectOptions(utils.enumToLookups(HistoryType)); }
        // private get historyTypeSearchOptions(): Array<ILookupItem> { return utils.selectOptions(utils.enumToLookups(HistoryType), "Any type..."); }
        
        private get userList(): Array<ILookupItem> { return this.$store.state.userList; }
        private assignToOptions: Array<ILookupItem> = [];

        private showFollowUpIcon(history: History): boolean {
            return history.followUp != null;
        }
        private followUpIcon(history: History): string {
            if (this.showFollowUpIcon(history) && history.followUp!.followUpDate < new Date()) return "fas fa-alarm-exclamation";
            else return "fas fa-alarm-clock";
        }
        private followUpColour(history: History): string {
            if (this.showFollowUpIcon(history) && history.followUp!.followUpDate < new Date()) return "red";
            else return "black";
        }
        private followUpTitle(history: History): string {
            if (!this.showFollowUpIcon(history)) return "";
            else if (utils.isEmptyId(history.followUp!.assignedToUserID)) return `Follow up by ${utils.dateText(history.followUp!.followUpDate)}`;
            else return `Follow up by ${utils.dateText(history.followUp!.followUpDate)}, assigned to ${utils.lookupDescription(history.followUp!.assignedToUserID, this.userList)}`;
        }

        private get historyDialogueTitle(): string {
            if (!this.history) return "- - -";
            if (utils.isEmptyId(this.history.id)) return "New History";
            return "Edit History";
        }

        private get hasFollowUp(): boolean {
            return this.history.followUp != null;
        }
        private set hasFollowUp(value: boolean) {
            if (value) this.history.followUp = new FollowUp();
            else this.history.followUp = null;
        }

        //
        // -- watchers
        //

        @Watch("supplierID")
        onSupplierIdChanged(value: number) {
            this.searchParameters.supplierID = this.supplierID;
            this.load();
        }

        @Watch("searchParameters.historyTypeID")
        onTypeIdChanged(value: boolean) {
            this.refreshHistories();
        }

        //
        // -- methods
        //

        async getAssignmentOptions() {
            const options = [];
            const params = new UserSearchParameters();
            params.isDeleted = TriState.False;
            params.isDormant = TriState.False;

            params.role = UserRole.Admin;
            let response = await apiClient.post("/api/user/searchLookups", params)
            options.push(...response)

            params.role = UserRole.Cqms;
            response = await apiClient.post("/api/user/searchLookups", params)
            options.push(...response)

            options.sort((a,b) => (a.description.toLowerCase() > b.description.toLowerCase()) ? 1 : ((b.description.toLowerCase() > a.description.toLowerCase()) ? -1 : 0));

            this.assignToOptions = utils.selectOptions(options, "Unassigned");
        }

        private iconUrl(document: ModelDocument): string {
            return utils.iconUrl(document);
        }

        async download(doc: ModelDocument) {
            if (!utils.isEmptyId(doc.id))
                fileDownload.download(doc);
        }

        async load() {
            this.$v.$reset();
            this.history.followUp = null;
            utils.resetObject(this.history);
            this.historyList = [];
            await this.refreshHistories();
        }

        private async loadHistoryTypes() {
            this.historyTypeList = await apiClient.get(`/api/lookup/search/${LookupGroup.HistoryType}`);
            const filteredOptions = this.historyTypeList.filter((lu: ILookupItem) => lu.id > 0 && !lu.isArchived)
            this.historyTypeOptions = [new LookupItem({ id: 0, description: "Please choose...", isArchived: false } as ILookupItem), ...filteredOptions];
            this.historyTypeSearchOptions = [new LookupItem({ id: 0, description: "Any type...", isArchived: false } as ILookupItem), ...filteredOptions];
        }

        async refreshHistories() {
            this.history.followUp = null;
            utils.resetObject(this.history);
            this.isWaiting = true;
            const responseData = await apiClient.post("api/history/search", this.searchParameters);
            this.historyList = responseData.list.map((c: IHistory) => new History(c));
            this.$emit("historyCountChanged", responseData.count); // update tab count badge in parent
            this.isWaiting = false;
        }

        async editHistory(historyData: IHistory, event: Event) {
            this.history.update(historyData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("historyDialogue");
            // refresh from server
            const serverHistoryData = await apiClient.get(`api/history/Load?id=${historyData.id}`, );
            this.history.update(serverHistoryData);
        }

        editNewHistory() {
            this.history.followUp = null;
            utils.resetObject(this.history);
            this.$v.$reset();
            this.history.supplierID = this.supplierID;
            this.$bvModal.show("historyDialogue");
        }

        cancelHistory() {
            this.$bvModal.hide("historyDialogue");
            this.history.followUp = null;
            utils.resetObject(this.history);
        }

        async saveHistory(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.history.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/history/save", this.history, event);
            if (this.history.isNew) {
                this.history.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("historyDialogue");
            await this.refreshHistories();
        }

        async deleteHistory(event: Event) {
            if (this.history.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Are you sure you want to delete this history record?", {
                title: "Delete History",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/history/delete", this.history, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("historyDialogue");
            await this.refreshHistories();
        }

        @Validations()
        validations() {
            const validations = {
                history: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.history.followUp = {} as any;
            validations.history.historyTypeID =  { isNonZero: (value: number) => +value > 0 };
            validations.history.followUp.followUpDate =  { isValid: (value: Date) => !this.hasFollowUp || utils.hasDateValue(value) };
            return validations;
        }
    }

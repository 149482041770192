import { mapData } from "@/stuff/DataMapping";

export interface IBuyerSupplierTrade {
    tradeID: number;
    description: string;
    isSelected: boolean;
}

export class BuyerSupplierTrade implements IBuyerSupplierTrade {

    constructor(data?:IBuyerSupplierTrade) {
        if (data) this.update(data);
    }

    update(data:IBuyerSupplierTrade): void {
        mapData(data, { root: () => this });
    }

    tradeID: number = 0;
    description: string = "";
    isSelected: boolean = false;

}
import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { FollowUp } from "@/model/FollowUp";
import { Document, IDocument } from "./Document";

export interface IHistory {
    id: number;
    supplierID: number;
    historyTypeID: number;
    when: Date;
    followUpBy: Date;
    notes: string;
    documentID: string;
    created: Date;
    createdByUserID: number;
    followUp: FollowUp|null;
    document: IDocument;
}

export class History implements IHistory {

    constructor(data?: IHistory) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IHistory) {
        mapData(data, { 
            root: () => this,
            followUp: () => new FollowUp(),
            document: () => new Document()
        });
    }

    id: number = 0;
    supplierID: number = 0;
    historyTypeID: number = 0;
    when: Date = new Date(utils.emptyDateValue);
    followUpBy: Date = new Date(utils.emptyDateValue);
    notes: string = "";
    documentID: string = "";
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    followUp: FollowUp|null = null;
    document: Document = new Document()

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get description() {
        return `History id= ${this.id}`;
    }
}


    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import FileUpload from "@/components/FileUpload.vue";
    import { required } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import { Prop } from "vue-property-decorator";
    import * as toastr from "toastr";
    import fileDownload from "@/stuff/FileDownload";
    import apiClient from "@/stuff/ApiClient";
    import { ILookupItem } from "@/model/LookupItem";
    import { ISupplierDocument, SupplierDocument } from "@/model/SupplierDocument";
    import { Document as ModelDocument } from "@/model/Document";
    import { SupplierDocumentSearchParameters } from "@/model/SupplierDocumentSearchParameters";
    import { DocumentCategory, DocumentVisibility } from "@/model/Enums";
    import utils from "@/stuff/Utils";
    
    @Component({
        components: { ApiButton, FileUpload }
    })
    export default class Region extends Vue {

        mounted() {
            this.searchParameters.visibilities = [];
            this.searchParameters.supplierID = this.supplierID;
            this.visibilityList = utils.enumToLookups(DocumentVisibility);
            for (const i of this.visibilityList) {
                if (i.description === "Cqms") i.description = "CQMS Only";
                if (i.description === "Suppliers") i.description = "Suppliers (and CQMS)";
                if (i.description === "Cqmm") i.description = "Buyers (and CQMS)";
            }
            this.visibilityOptions = utils.selectOptions(this.visibilityList); 
            this.load();
        }

        //
        // -- properties
        //

        @Prop({ default: 0 })
        private supplierID!: number;

        private isWaiting: boolean = true;

        private readonly searchParameters = new SupplierDocumentSearchParameters();
        private readonly supplierDocument = new SupplierDocument();
        private supplierDocumentList: Array<ISupplierDocument> = [];
 
        private visibilityList: Array<ILookupItem> = [];
        private visibilityOptions: Array<ILookupItem> = [];

        private get supplierDocumentDialogueTitle(): string {
            if (!this.supplierDocument) return "- - -";
            if (this.supplierDocument.isNew) return "New Document";
            return "Edit " + this.supplierDocument.description;
        }

        private get canDeleteSupplierDocument() {
            return !this.supplierDocument.isNew;
        }

        //
        // -- watchers
        //

        @Watch("supplierID")
        onSupplierIdChanged() {
            this.searchParameters.supplierID = this.supplierID;
            this.load();
        }

        @Watch("searchParameters.visibility")
        onVisibilityChanged() {
            this.refreshSupplierDocuments();
        }

        //
        // -- methods
        //

        async load() {
            this.$v.$reset();
            utils.resetObject(this.supplierDocument);
            this.supplierDocumentList = [];
            await this.refreshSupplierDocuments();
        }

        async refreshSupplierDocuments() {
            utils.resetObject(this.supplierDocument);
            this.isWaiting = true;
            const responseData = await apiClient.post("api/supplierDocument/search", this.searchParameters);
            this.supplierDocumentList = responseData.map((c: ISupplierDocument) => new SupplierDocument(c));
            this.$emit("documentCountChanged", responseData.length); // update tab count badge in parent
            this.isWaiting = false;
        }

        private iconUrl(document: ModelDocument): string {
            return utils.iconUrl(document);
        }

        async download(doc: ModelDocument) {
            fileDownload.download(doc);
        }

        async editSupplierDocument(supplierDocumentData: ISupplierDocument) {
            this.supplierDocument.update(supplierDocumentData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("supplierDocumentDialogue");
            // refresh from server
            const serverSupplierDocumentData = await apiClient.get(`api/supplierDocument/Load?supplierID=${supplierDocumentData.supplierID}&documentID=${supplierDocumentData.documentID}`);
            this.supplierDocument.update(serverSupplierDocumentData);
        }

        editNewSupplierDocument() {
            utils.resetObject(this.supplierDocument);
            this.$v.$reset();
            this.supplierDocument.supplierID = this.supplierID;
            this.$bvModal.show("supplierDocumentDialogue");
        }

        cancelSupplierDocument() {
            this.$bvModal.hide("supplierDocumentDialogue");
            utils.resetObject(this.supplierDocument);
        }

        async saveSupplierDocument(event: Event) {
            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.supplierDocument.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            this.supplierDocument.document.category = DocumentCategory.Supplier;
            const response: { newDocumentID: string } =  await apiClient.post("/api/supplierDocument/save", this.supplierDocument, event);
            if (this.supplierDocument.isNew) {
                this.supplierDocument.document.id = response.newDocumentID;
                this.supplierDocument.documentID = response.newDocumentID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("supplierDocumentDialogue");
            await this.refreshSupplierDocuments();
        }

        async deleteSupplierDocument(event: Event) {
            if (this.supplierDocument.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.supplierDocument.description + "'?", {
                title: "Delete SupplierDocument",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/supplierDocument/delete", this.supplierDocument, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("supplierDocumentDialogue");
            await this.refreshSupplierDocuments();
        }

        @Validations()
        validations() {
            const validations = {
                supplierDocument: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.supplierDocument.description = { required };
            validations.supplierDocument.visibility = { hasValue: (value: number) => +value > 0 };
            validations.supplierDocument.document = { hasDocument: (value: ModelDocument) => value != null && value.hasFile };
            return validations;
        }
    }
